<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useSalutation } from '@/composables/useSalutation';
import { useCountry } from '@/composables/useCountry';
import { useGoogleReCaptcha } from '~/composables/useGoogleRecaptcha';

const { recaptcha, isRecaptchaActive } = useGoogleReCaptcha();

const { salutations } = useSalutation();
const { countries } = useCountry();

const config = useRuntimeConfig();
const { t, locale } = useI18n();
const { pushSuccess, pushError } = useNotifications();

const { apiInstance } = useShopwareContext();

const props = defineProps<{
  content: {
    backgroundColor: string;
    backgroundImage: string;
    data: {
      title: string;
      extensions: {
        config: {
          privacyNoticeText: string;
          buttonLabel: string;
          widgetSource: boolean;
          withoutSubscription: boolean;
          useNewsletterWidgetForm: boolean;
          useBrandColor: boolean;
          confirmationText: string;
          additionalNoteText: string;
          showAdditionalNote: boolean;
        };
      };
      groups: {
        position: number;
        extensions: {
          config: {
            isModal: boolean;
            allowDuplication: boolean;
            duplicationButtonLabel: string | null;
            maxReps: number | null;
          };
        };
        fields: {
          technicalName: string;
          translated: {
            label: string;
          };
          width: number;
          required: boolean;
          config: {
            entity?: string;
          };
          errorMessage?: string;
          type: string;
          placeholder: string;
        }[];
      }[];
      type: {
        value: string;
      };
      infoText: {
        value: string;
      };
      privacyText: {
        value: string;
      };
      buttonText: {
        value: string;
      };
      receivers: {
        value: string;
      };
    };
  };
}>();

const FormData = {
  headline: props.content.data.title,
  newsletterLabel: props.content.data.extensions?.config?.privacyNoticeText,
  buttonText: props.content.data.extensions?.config?.buttonLabel,
  isNewsletterWidgets:
    props.content.data.extensions?.config?.useNewsletterWidgetForm,
  title: props.content.data.title,
  privacyText: props.content.data.extensions?.config?.privacyNoticeText,
  backgroundColor: props.content.backgroundColor,
  backgroundImage: props.content.backgroundImage,
  backgroundBrandColor: props.content.data.extensions?.config?.useBrandColor,
  contentModalSuccess: props.content.data?.extensions?.config?.confirmationText,
  additionalNoteCheck:
    props.content.data?.extensions?.config?.showAdditionalNote,
  additionalNoteText:
    props.content.data?.extensions?.config?.additionalNoteText,
};

const blockConfig = computed(() => props.content?.data?.extensions?.config);
const source = computed(() => blockConfig.value?.widgetSource);
const withoutSubscription = computed(
  () => blockConfig.value?.withoutSubscription || false
);

// TODO: add type IFormGroup from design-system
const ecrmForm: any = [];
const ecrmFormDuplicates: any = [];

props.content.data.groups.forEach((group) => {
  const formGroup: any = {
    positionFormGroup: group.position,
    fieldList: group.fields.map((field) => ({
      type: field.type,
      name: field.technicalName,
      label: field.translated.label,
      width: field.width,
      placeholder: field.placeholder,
      value: '',
      entityName: '',
      ...(field.config?.entity === 'salutation' && { optionList: salutations }),
      ...(field.config?.entity === 'country' && { optionList: countries }),
      disabled: false,
      ceAlias: '',
      ...(field.required && {
        validationList: [
          {
            message: field.errorMessage,
            rule: (value: unknown) => value,
          },
        ],
      }),
    })),
  };

  if (group?.extensions?.config?.allowDuplication) {
    ecrmFormDuplicates.push(formGroup);
    return;
  }

  ecrmForm.push(formGroup);
});

const storefrontUrl =
  config.brandStores[config.BRAND] ||
  (typeof window !== 'undefined' && window.location.origin) ||
  '';

/**
 * Newsletter subscription
 */

const successSubscribe = ref(false);
const genericFail = ref(false);
const constraintViolation = ref(false);
const error = ref(false);
const alreadyRegistered = ref(false);

const { newsletterSubscribe, fetchContentFromNewsletterPage, htmlContent } =
  useNewsletter();

const invokeNewsletterRegistration = async (email: string) => {
  const data: any = {
    email,
    option: 'subscribe',
    storefrontUrl: storefrontUrl ? `${storefrontUrl}/${locale.value}` : '',
  };

  if (isRecaptchaActive()) {
    data._grecaptcha_v3 = await recaptcha('newsletter');
  }

  await newsletterSubscribe(data)
    .then(() => {
      // pushSuccess(t('components.newsletter.success'));
      successSubscribe.value = true;
    })
    .catch((e) => {
      console.error(e);

      error.value = true;

      if (e.statusCode === 500) {
        genericFail.value = true;
      }

      if (e.statusCode === 409) {
        alreadyRegistered.value = true;
      }

      if (e.statusCode === 400) {
        constraintViolation.value = true;
      }
    });
};

const submitForm = async (data: Record<string, any>) => {
  await apiInstance.invoke
    .post('/store-api/crossengage/user/update', {
      ...data,
      source: source.value,
      subscribe: !withoutSubscription.value,
      storefrontUrl: storefrontUrl ? `${storefrontUrl}/${locale.value}` : '',
    })
    .then(() => {
      successSubscribe.value = true;
    })
    .catch((e) => {
      console.error(e);
      error.value = true;

      if (e.statusCode === 500) {
        genericFail.value = true;
      }

      if (e.statusCode === 409) {
        alreadyRegistered.value = true;
      }

      if (e.statusCode === 400) {
        constraintViolation.value = true;
      }
    });
};

const resetMessages = () => {
  successSubscribe.value = false;
  alreadyRegistered.value = false;
  genericFail.value = false;
  constraintViolation.value = false;
  error.value = false;
};

/**
 * This computed property creates a map of technical
 * names to placeholders for form fields
 */
const placeholders = computed(() => {
  const placeholderMap = new Map<string, string>();
  props.content.data.groups.forEach((group) => {
    group.fields.forEach((field) => {
      placeholderMap.set(field.technicalName, field.placeholder);
    });
  });

  return placeholderMap;
});
</script>

<template>
  <div class="cms-element-ecrm-form" :content="content">
    <DynamicForm
      :is-newsletter-widgets="FormData.isNewsletterWidgets"
      :is-newsletter-modal="true"
      :success-send="successSubscribe"
      :success-subscribe="successSubscribe"
      :already-registered="alreadyRegistered"
      :generic-fail="genericFail"
      :constraint-violation="constraintViolation"
      :error="error"
      :form-field-group-list="ecrmForm"
      :title="FormData.title"
      :privacy-checkbox="false"
      :privacy-text="FormData.privacyText"
      :headline="FormData.headline"
      :newsletter-label="FormData.newsletterLabel"
      :button-label="FormData.buttonText"
      :background-color="FormData.backgroundColor"
      :background-image="FormData.backgroundImage"
      :background-brand-color="FormData.backgroundBrandColor"
      :content-modal-success="FormData.contentModalSuccess"
      :additional-note-check="FormData.additionalNoteCheck"
      :additional-note-text="FormData.additionalNoteText"
      :modal-content="htmlContent"
      :newsletter-placeholder="placeholders.get('email')"
      @show-modal="fetchContentFromNewsletterPage"
      @submit-newsletter="invokeNewsletterRegistration"
      @submit="submitForm"
      @close-modal="resetMessages"
    />
  </div>
</template>

<style lang="scss" scoped></style>
